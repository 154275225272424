.horizontal-menu {
  .top-navbar {
    font-weight: 400;
    background: $horizontal-top-bg;
    border-bottom: 1px solid $border-color;

    .navbar-brand-wrapper {
      width: auto;
      height: $horizontal-top-navbar-height;
      .navbar-brand {
        color: lighten(color(gray-dark), 20%);
        font-size: 1.5rem;
        margin-right: 0;
        padding: 0.25rem 0;
        text-align: left;

        &.brand-logo-mini {
          display: none;
          padding-left: 0;
          text-align: center;
          img {
            width: 30px;
            max-width: 100%;
            margin: auto;
          }
        }

        &:active,
        &:focus,
        &:hover {
          color: lighten(color(gray-dark), 10%);
        }

        img {
          max-width: 100%;
          width: 149px;
          height: 34px;
          margin: auto;
          vertical-align: middle;
        }

        @media (max-width: 991px) {
          width: 55px;
          &.brand-logo {
            display: none;
          }
          &.brand-logo-mini {
            display: block;
          }
        }
      }
    }

    .navbar-menu-wrapper {
      color: $horizontal-top-menu-item-color;
      width: auto;
      height: $horizontal-top-navbar-height;
      @include flex-grow(1);
      @media (max-width: 991px) {
        width: calc(100% - 55px);
        padding-left: 15px;
        padding-right: 0;
      }

      .navbar-toggler {
        border: 0;
        color: inherit;
        font-size: 1.5rem;
        &:not(.navbar-toggler-right) {
          @media (max-width: 991px) {
            display: none;
          }
        }
        &.navbar-toggler-right {
          @media (max-width: 991px) {
            padding-left: 15px;
            padding-right: 11px;
          }
        }
      }
      .navbar-nav {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .flex-row;
        .rtl & {
          padding-right: 0;
        }
        .nav-item {
          margin-left: 1rem;
          margin-right: 1rem;
          @media (max-width: 767px) {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
          .nav-link {
            color: inherit;
            font-size: $horizontal-top-font-size;
            @include display-flex;
            @include align-items(center);
            i {
              font-size: 14px;
            }
          }
          &.nav-profile {
            @extend .d-flex;
            @extend .align-items-center;
            .nav-profile-img {
              position: relative;
              img {
                width: 39px;
                height: 39px;
                border-radius: 100%;
              }
            }
            .nav-profile-text {
              margin-left: 1.25rem;
              line-height: 1;
              .rtl & {
                margin-left: 0;
                margin-right: 1.25rem;
              }
              p {
                margin-bottom: 0;
              }
              .online-color,
              i {
                color: #a0a0a0;
              }
              @media (max-width: 767px) {
                display: none;
              }
            }
            .nav-link {
              &::after {
                font-size: 1rem;
              }
            }
          }
          &.nav-search {
            margin-left: 49px;
            .rtl & {
              margin-left: 0;
              margin-right: 49px;
            }
            .input-group {
              border-radius: 4px;
              max-width: 725px;
              max-height: 39px;

              .form-control,
              .input-group-text {
                background: #e4e9f0;
                border: 0;
                color: $horizontal-top-menu-item-color;
                padding: 0;
              }
              .input-group-text {
                max-height: 39px;
                i {
                  font-size: 15px;
                  padding: 12px 15px;
                  color: #a0a0a0;
                }
              }
              .form-control {
                max-height: 39px;
                padding: 12px 534px 12px 0;
                @media (max-width:1198px) {
                  padding: 12px 0 12px 359px;
                }
                .rtl & {
                  padding: 12px 0 12px 534px;
                  @media (max-width:1198px) {
                    padding: 12px 0 12px 359px;
                  }
                }
                @include placeholder {
                  color: $horizontal-top-menu-item-color;
                  font-size: 14px;
                  font-weight: $font-weight-semibold;
                }
              }
            }
          }
          &.dropdown {
            .navbar-dropdown {
              position: absolute;
              font-size: 0.9rem;
              margin-top: 0;
              right: 0;
              left: auto;
              top: 70px;
              .rtl & {
                right: auto;
                left: 0;
              }

              .dropdown-item {
                @extend .d-flex;
                @extend .align-items-center;
                margin-bottom: 0;
                padding: 0.687rem 1.562rem;
                cursor: pointer;
                &:hover {
                  color: inherit;
                }

                i {
                  font-size: 17px;
                }

                .badge {
                  margin-left: 2.5rem;
                }
                .ellipsis {
                  max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .rtl & {
                  i {
                    @extend .mr-0;
                    margin-left: 10px;
                  }
                  .badge {
                    margin-left: 0;
                    margin-right: 2.5rem;
                  }
                }
              }

              .dropdown-divider {
                margin: 0;
              }
            }
            i {
              margin-right: 0.5rem;
              vertical-align: middle;
            }
            @media (max-width: 991px) {
              position: static;
              .navbar-dropdown {
                left: 20px;
                right: 20px;
                top: $horizontal-top-navbar-height;
                width: calc(100% - 40px);
              }
            }
            .dropdown-menu {
              border: none;
              -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
              box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
              @extend .dropdownAnimation;
              &:before {
                @media (min-width: 992px) {
                  content: "";
                  border: 10px solid transparent;
                  height: 0;
                  width: 0;
                  border-bottom: 8px solid white;
                  position: absolute;
                  right: 10px;
                  bottom: 100%;
                  border-radius: 2px;
                }
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
        &.navbar-nav-right {
          @extend .align-self-stretch;
          @extend .align-items-stretch;
          .nav-item {
            @include display-flex;
            @include align-items(center);
          }
          @media (min-width: 992px) {
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
    }
  }
  .bottom-navbar {
    background: $horizontal-bottom-bg;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    @include display-flex;
    @include align-items(center);
    @include transition-duration(3s);
    @include transition-property(position, left, right, top, z-index);
    box-shadow: $horizontal-menu-box-shadow;
    -webkit-box-shadow: $horizontal-menu-box-shadow;
    -moz-box-shadow: $horizontal-menu-box-shadow;
    @include media-breakpoint-down(md) {
      display: none;
      &.header-toggled {
        display: block;
        max-height: calc(100vh - #{$horizontal-top-navbar-height});
        overflow: auto;
      }
    }

    .page-navigation {
      position: relative;
      width: 100%;
      z-index: 99;
      @include justify-content(space-between);
      @include transition-duration(0.2s);
      @include transition-property(background, box-shadow);
      @include media-breakpoint-down(md) {
        border: none;
      }

      > .nav-item {
        line-height: 1;
        text-align: left;
        border-right: 1px solid rgba(151, 151, 151, 0.34);
        @include media-breakpoint-down(md) {
          display: block;
          width: 100%;
          border-right: none;
        }

        &:first-child {
          @include media-breakpoint-up(lg) {
            > .nav-link {
              padding-left: 0;
            }
          }
        }

        &:last-child {
          border-right: none;
          @include media-breakpoint-up(lg) {
            > .nav-link {
              padding: 15px 0 15px 13px;
              border: 0;
              i {
                font-size: 20px;
              }
            }
          }
        }

        > .nav-link {
          color: $horizontal-bottom-menu-item-color;
          padding: 22px 12px 22px 7px ;
          line-height: 1;
          font-weight: 400;
          @extend .d-flex;
          @extend .align-items-center;
          .menu-title {
            font-size: $horizontal-bottom-menu-item-font-size;
            font-weight: 400;
          }
          .menu-icon {
            margin-right: 10px;
            font-size: $horizontal-menu-icon-font-size;
            color: $horizontal-bottom-menu-item-icon-color;
            font-weight: 400;

            .rtl & {
              margin-right: 0;
              margin-left: 10px;
            }
          }
          .dropdown-menu-right {
            &:before {
              @media (min-width: 992px) {
                content: "";
                border: 10px solid transparent;
                height: 0;
                width: 0;
                border-bottom: 8px solid white;
                position: absolute;
                right: 10px;
                bottom: 100%;
                border-radius: 2px;
              }
            }
          }
          .menu-arrow {
            margin-left: 5px;
            display: inline-block;
            @include transform(rotate(0deg));
            @include transform-origin(center);
            @include transition-duration($action-transition-duration);
            opacity: 0.53;
            .rtl & {
              margin-left: 0;
              margin-right: 5px;
            }

            &:before {
              content: "\f140";
              font-family: "Material Design Icons";
              font-style: normal;
              display: block;
              font-size: 18px;
              line-height: 10px;
            }
          }
        }

        @include media-breakpoint-up(md) {
          &:hover {
            @include show-submenu();
          }
        }

        @include media-breakpoint-down(md) {
          &.show-submenu {
            @include show-submenu();
          }
        }

        &.active {
          > .nav-link {
            position: relative;
            &:before {
              position: absolute;
              content: "";
              left: 0;
              bottom: -2px;
              width: 100%;
              height: 5px;
              @media (max-width: 991px) {
                left: -15px;
                top: 0;
                bottom: 0;
                height: 100%;
                width: 5px;
              }
            }
            .menu-title,
            i,
            .menu-arrow {
              color: $horizontal-bottom-menu-item-active-color;
              font-weight: $font-weight-semibold;
            }
          }
        }

        .submenu {
          display: none;
          @extend .dropdownAnimation;
          @media (max-width: 991px) {
            background: $white;
          }

          ul {
            list-style-type: none;
            padding-left: 0;

            li {
              display: block;
              line-height: 20px;

              a {
                display: block;
                padding: 5px 10px;
                font-weight: 300;
                color: $horizontal-bottom-menu-dropdown-item-color;
                text-decoration: none;
                text-align: left;
                margin: 4px 0;
                @include ellipsor;
                @include transition-duration(0.2s);
                @include transition-property(background);
                @include border-radius(6px);

                &:hover {
                  color: theme-color(primary);
                  @include transition(color 0.1s linear);
                  &:before {
                    background: theme-color(primary);
                  }
                }
                &.active {
                  color: theme-color(primary);
                }
              }

              &.active {
                a {
                  color: theme-color(primary);
                  &:before {
                    background: theme-color(primary);
                  }
                }
              }
            }
          }
        }

        &:not(.mega-menu) {
          position: relative;
          .submenu {
            @include menu-style;
            left: 0;
            @include media-breakpoint-down(md) {
              top: 0;
            }
            ul {
              width: auto;
              padding: 25px;
              @include media-breakpoint-down(md) {
                padding: 0 23px;
              }
            }
          }
        }

        &.mega-menu {
          .submenu {
            @include menu-style;
            width: 100%;
            left: 0;
            right: 0;
            padding: 25px;

            .category-heading {
              font-size: 0.875rem;
              font-weight: 500;
              text-align: left;
              color: $black;
              padding: 1rem 0;
              margin-bottom: 0;
              @extend .text-primary;
            }
            .col-group-wrapper {
              padding: 0 1rem;
            }
            @include media-breakpoint-down(md) {
              padding: 0 32px;

              .col-group-wrapper {
                margin-left: 0;
                margin-right: 0;
                padding: 0;

                .col-group {
                  padding-left: 0;
                  padding-right: 0;
                  margin-bottom: 20px;

                  .category-heading {
                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.fixed-on-scroll {
    .bottom-navbar {
      @extend .fixed-top;
      border-bottom: 1px solid $border-color;
    }
  }
  @media (max-width: 991px) {
    position: fixed;
    z-index: 1030;
    top: 0;
    left: 0;
    right: 0;
  }
}
